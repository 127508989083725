import { graphql } from "gatsby"
import React, { useContext } from "react"
import Background from "../components/atoms/background"
import Chapter from "../components/molecules/chapter/chapter"
import ChapterNav from "../components/molecules/chapter/chapterNav"
import SEO from "../components/seo"
import { CursorContext } from '../context/cursorContext'
import ScrollOverlay from '../components/organisms/ahoy/scrollOverlay'

export const query = graphql`
  query AhoyPage {
    page: wpPage(slug: { eq: "ahoy" }) {
      background {
        color: presets
        custom
      }
      ahoyFields {
        chapters {
          title
          content
        }
      }
    }
  }
`

const AhoyPage = ({ data, location }) => {
  const { page } = data
  const { cursorConfig, setCursor } = useContext(CursorContext);

  if (page !== null) {
    return (
      <div 
        onMouseEnter={() => setCursor({
            ...cursorConfig,
            rotation: -30,
            scale: '1',
            color: 'var(--darkgrey)',
            shape: "default"
        })}
      >
        <SEO />
        <Background preset={page.background.color} custom={page.background.custom} />
        <ChapterNav items={page.ahoyFields.chapters} />
        <div className="container" id="ahoy-container">
        <ScrollOverlay />
          {page.ahoyFields.chapters.map(element => {
            return (
              <Chapter
                key={element.title}
                title={element.title}
                content={element.content}
              />
            )
          })}
        </div>
      </div>
    )
  } else {
    return <></>
  }
}

export default AhoyPage
