import React from "react"

import { Element } from "react-scroll";

import './chapter.scss'

const Chapter = ({content, title}) => {

    return (
        <Element className="chapter" name={title ? title : "name"} id={title ? title : "name"}>
            <h1>{title}</h1>
            <div className="content" dangerouslySetInnerHTML={{ __html: content }} />
        </Element>
    )
}

export default Chapter
