import React from "react"

import { Link } from "react-scroll"

import './chapter.scss'

const ChapterNav = ({ items }) => {

    return (
        <nav className="page-menu">
            <ul>
                {
                    items.map(element => {
                        return (
                            <li key={element.title}>
                                <Link
                                    activeClass="active"
                                    to={element.title}
                                    spy={true}
                                    smooth={true}
                                    offset={-280}
                                    duration={500}
                                    containerId="container"
                                >
                                    {element.title}
                                </Link>
                            </li>
                        )
                    })
                }
            </ul>
        </nav>
    )
}

export default ChapterNav