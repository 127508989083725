import React, { useContext, useEffect } from 'react'
import './scrollOverlay.scss'
import { animateScroll as scroll } from 'react-scroll'
import { CursorContext } from "../../../context/cursorContext"
import { useWindowSize } from "../../../hooks/useWindow"

const ScrollOverlay = () => {
  const { cursorConfig, setCursor } = useContext(CursorContext)
  
  const { width } = useWindowSize()

  useEffect(() => {
    const scrollBarWidth =
      width - document?.getElementById("ahoy-container")?.clientWidth || 15
    document.querySelector('.overlay-top').style.width = `calc(100% - ${scrollBarWidth}px)`
    document.querySelector('.overlay-bottom').style.width = `calc(100% - ${scrollBarWidth}px)`
  }, [])

  const onWheel = (event) => {
    const container = document.getElementById('container')
    container?.scrollTo({
      top: container.scrollTop + event.deltaY
    })
  }

  return (
    <>
      <div
        className="overlay-top"
        onMouseEnter={() =>
          setCursor({
            ...cursorConfig,
            rotation: 0,
            translation: "-25%, 50%",
            scale: "2",
            color: "var(--yellow)",
            shape: "triangle",
          })
        }
        onMouseLeave={() =>
          setCursor({
            ...cursorConfig,
            color: "var(--darkgrey)",
            shape: "default",
            rotation: -30,
            translation: "-6px, -6px",
            scale: "1",
          })
        }
        onClick={() => {
          scroll.scrollMore(-window.innerHeight * 0.75, {
            containerId: "container",
            smooth: true,
          })
        }}
        onWheel={onWheel}
      ></div>

      <div
        className="overlay-bottom"
        onMouseEnter={() =>
          setCursor({
            ...cursorConfig,
            rotation: cursorConfig.rotation === 90 ? 180 : -180,
            translation: "-75%, -150%",
            scale: "2",
            color: "var(--yellow)",
            shape: "triangle",
          })
        }
        onMouseLeave={() =>
          setCursor({
            ...cursorConfig,
            color: "var(--darkgrey)",
            shape: "default",
            rotation: -30,
            translation: "-6px, -6px",
            scale: "1",
          })
        }
        onClick={() => {
          scroll.scrollMore(window.innerHeight * 0.75, {
            containerId: "container",
            smooth: true,
          })
        }}
        onWheel={onWheel}
      ></div>
    </>
  )
}

export default ScrollOverlay
